import React from "react";
import {Link} from "react-router-dom";
import {bindActionCreators, compose} from "redux";
import {addVariableData} from "../actions";
import {connect} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import {pushHistory} from "./Tools";

/**
 *   Permet d'effectuer des opérations à l'ouverture d'un composant
 */
class OpenItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content_link: null,
            content_id: null,
            isVariable: null,
            dialog_PNL: false,
            dialog_PL: false,
        }

        this.openItem = this.openItem.bind(this);
    }

    getContentAndVariableSetting(contentToRedirect){
        let regExParam = /(\?|&)(?<var>[^=]+)=(?<value>.[^&]*)/gim;
        let regExId = /^(\d*)/i;

        if(regExParam.test(contentToRedirect)){
            return {
                content_id : contentToRedirect.match(regExId)[0],
                isVariable :  true
            }
        } else {
            return {
                content_id : contentToRedirect,
                isVariable : false
            }
        }
    }

    componentDidMount() {
        const Data = this.getContentAndVariableSetting(this.props.contentToRedirect);
        let content_id = Data.content_id;
        let isVariable = Data.isVariable;

        if(Object.keys(this.props.routes.RoutePathArray).length > 0){
            this.setState({
                content_link:  this.props.routes.RoutePathArray[content_id] ? this.props.routes.RoutePathArray[content_id].url : "/",
                content_id: content_id,
                isVariable: isVariable
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        const newData = this.getContentAndVariableSetting(this.props.contentToRedirect);
        let new_content_id = newData.content_id.toString();
        let isVariable = newData.isVariable;

        if(this.state.content_id !== new_content_id){
            this.setState({
                content_link:  this.props.routes.RoutePathArray[new_content_id] ? this.props.routes.RoutePathArray[new_content_id].url : "/",
                content_id: new_content_id,
                isVariable: isVariable
            })
        }
    }

    openItem = (e) => {
        e.preventDefault();

        if(this.state.isVariable){
            let regExParam = /(\?|&)(?<var>[^=]+)=(?<value>.[^&]*)/gim;
            let matches = this.props.contentToRedirect.matchAll(regExParam);

            for(let match of matches){
                if(match.groups.var && match.groups.value){
                    let tmp_var = {};
                    tmp_var[match.groups.var] = match.groups.value

                    this.props.addVariableData(tmp_var)
                }
            }
        }

        let newComp = this.props.routes.RoutePathArray ? this.props.routes.RoutePathArray[this.state.content_id] : null;

        if(newComp){
            if(newComp.accessibility === "protege" && !this.props.dataAuth.success){
                this.setState({dialog_PNL: true})
            } else if (newComp.accessibility === "protege" && this.props.dataAuth.success) {
                this.setState({dialog_PL: true})
            } else if(
                (
                    newComp.type === "order_history"
                    || newComp.type === "planning_mobinaute"
                    || newComp.type === "wallet"
                    || newComp.type === "wallet_stripe"
                    || newComp.type === "list_announce_mobinaute"
                ) &&  !this.props.dataAuth.success
            ){
                this.setState({dialog_PNL: true})
            }else {
                pushHistory(this.state.content_link)
            }
        } else {
            pushHistory(this.state.content_link)
        }
    };

    render() {
        const dyn_string = this.props.t

        let protect_not_logged = (
            <Dialog
                open={this.state.dialog_PNL}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{dyn_string('auth_alert_title_info_protected_content')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dyn_string('auth_alert_msg_info_protected_content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.setState({dialog_PNL: false})}
                        style={{backgroundColor: "white", borderColor: this.props.styles.button.backgroundColor, color:this.props.styles.button.backgroundColor, border: "solid 1px"}}
                    >
                        {dyn_string('g_btn_ok')}
                    </Button>
                    <Link to={{pathname: this.props.routes.PathIdentification, state: {id: this.state.content_id}}}>
                        <Button onClick={() => {this.setState({dialog_PNL: false})}} style={{backgroundColor: this.props.styles.button.backgroundColor, borderColor: this.props.styles.button.backgroundColor, color: this.props.styles.button.color}}>
                            {dyn_string('auth_btn_login')}
                        </Button>
                    </Link>
                </DialogActions>
            </Dialog>
        );

        let protect_logged = (
            <Dialog
                open={this.state.dialog_PL}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{dyn_string('auth_alert_title_info_protected_content')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dyn_string('auth_btn_content_limited')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.setState({dialog_PL: false})}
                        style={{backgroundColor: this.props.styles.button.backgroundColor, borderColor: this.props.styles.button.backgroundColor, color: this.props.styles.button.color}}
                    >
                        {dyn_string('g_btn_ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );

        let customStyle;

        if(this.props.isFor === 'nav' || this.props.isFor === 'cart' || this.props.isFor === "listMap"){
            customStyle = {color: "black", textDecoration: "none"}
        } else if(this.props.isFor === 'navResp') {
            customStyle = {
                textAlign: "left",
                paddingLeft: "5px",
                textTransform : "none",
                fontSize: this.props.styles.menu.fontSize + "px",
                fontFamily: this.props.styles.menu.text_font,
                color: this.props.styles.menu.text_color,
                width: "100%",
                height: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }
        } else if(this.props.isFor === 'navIdentLogged'){
            customStyle = { color: this.props.styles.menu.text_color, textDecoration: "none" , borderLeft: "1px solid #D3D3D3", paddingLeft: "30px" }
        } else if(this.props.isFor === 'category'){
            customStyle = {  textDecoration: "none", color:"black"  }
        } else {
            customStyle = null;
        }

        if(this.state.content_link){
            return (
                <React.Fragment>
                    <Link
                        to={this.state.content_link}
                        onClick={this.openItem}
                        style={customStyle}
                    >
                        {this.props.children}
                    </Link>

                    {protect_not_logged}
                    {protect_logged}
                </React.Fragment>
            )
        } else {
            return <></>
        }
    }
}

const mapStateToProps = (state) => ({
    variableStorage: state.variableStorage,
    data: state.content,
    dataAuth : state.contentauth,
    dataAuthSSO : state.contentauthSso,
    styles: state.style,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addVariableData
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(OpenItem);
