import React from "react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {
    requestGetMarketData,
    requestGetPlanningsData,
    addMobinauteData,
    addProfilFormData,
    setRoutesInfo,
    setRedirectError,
    resetMobinauteData,
    setAppInnerSize,
    requestGetConnectorsData
} from "../../actions";
import {Route, Router, Switch} from "react-router-dom";
import Footer from "../Footer";
import NavBar from "../NavBar";
import {
    createEventObject, dateToLocaleString,
    getQueryVariable,
    isResponsive,
    isSame,
    sortArray,
    urlrewriting,
} from "../../helpers/Tools";
import {redirect, Copyright, constructNodeProduct, constructNodeAnnounce} from "./Home.functions";
import Page from "../../views/Page/Page";
import Event from "../../views/Planning/Event/Event";
import Category from "../../views/Category/Category.Main";
import Graph from "../../views/GraphicalView/Graph";
import Pdf from "../../views/Media/Pdf/Pdf";
import Web from "../../views/Media/Web";
import Search from "../../views/Search/Search";
import Product from "../../views/Market/Product/Product";
import Cart from "../../views/Market/Cart/Cart";
import OrderHistory from "../../views/Market/OrderHistory";
import video from "../../views/Media/Video";
import Photo from "../../views/Media/Photo";
import Planning from "../../views/Planning/Planning";
import Identification from "../../views/Mobinaute/MobinauteIdentification/Mobinaute.Identification";
import UpdatePassword from "../../views/Mobinaute/MobinauteUpdatePassword/Mobinaute.UpdatePassword";
import Formulaire from "../../views/Formulaire/Formulaire";
import PageNotFound from "../../views/ContentUndefined/PageNotFound";
import Box from "@material-ui/core/Box";
import {PopUpLoader, ShapperLoader} from "../../components/ShapperLoader/ShapperLoader";
import Playlist from "../../views/Media/Playlist";
import {PropsRoute} from '../../helpers/PropsRoute'
import {withTranslation} from "react-i18next";
import {StripeWallet} from '../../views/Mobinaute/MobinauteWallet/StripeWallet/Stripe.Wallet'
import {TagWallet} from '../../views/Mobinaute/MobinauteWallet/TagWallet/Tag.Wallet'
import Announce from "../../views/Announce/Announce";
import AnnounceMobinaute from "../../views/Announce/AnnounceMobinaute/Announce.Mobinaute";
import {RedirectError} from "./Home.popup";

//array containing path for Routes
let PathUpdatePassword = "";
let PathIdentification = "";
let PathToCart = "";
let RoutePathArray = {};


class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
            connected: false,
            result: null,
            loading: true,
            end: false,
            isResponsive: false,
            mode: getQueryVariable("mode"),
        };
    }

    updateDimensions = (prevState) => {
        let calculatedHeight = window.innerHeight;

        if(isResponsive(window.innerWidth)){
            calculatedHeight -= 50;
        } else {

            let footer = document.getElementById('footer');
            let navbar = document.getElementById('navbar');

            if(footer) calculatedHeight -= footer.offsetHeight;
            if(navbar) {
                calculatedHeight -= navbar.offsetHeight;
                calculatedHeight -= 15; //margin-bottom
            }

            if(this.props.application.getApp.webParams.header_url){

                let navbarHeader = document.getElementById('navbar-header');

                if(navbarHeader) calculatedHeight -= navbarHeader.offsetHeight;

            }
        }

        if(this.state.height !== calculatedHeight || (prevState && (prevState.width !== window.innerWidth))){

            this.props.setAppInnerSize({
                innerHeight: calculatedHeight,
                innerWidth: window.innerWidth
            })

            this.setState({
                width: window.innerWidth,
                height: calculatedHeight,
                isResponsive: isResponsive(window.innerWidth)
            });
        }
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    constructResult(prevState, isFor){
        let result_tmp;
        let connected = false;

        //Choix du content à utiliser
        if(this.props.dataAuth.success){
            result_tmp =  this.props.dataAuth.result
            connected = true
        }else  if(this.props.dataSSO.success){
            result_tmp =  this.props.dataSSO.result
            connected = true
        }
        else if(this.props.data.success){
            result_tmp = this.props.data.result
        }

        if(this.state.connected !== connected)
            this.setState({connected: connected})

        if(result_tmp) {
            //Découpage des Contenu pour le Profil_form et le mobinaute
            if (result_tmp.find((element) => element.mobinaute)) {
                this.props.addMobinauteData(result_tmp.find((element) => element.mobinaute).mobinaute)
                result_tmp.splice(result_tmp.findIndex((element) => element.mobinaute), 1);
            }

            if (result_tmp.find((element) => element.profil_form)) {
                this.props.addProfilFormData(result_tmp.find((element) => element.profil_form).profil_form)
                result_tmp.splice(result_tmp.findIndex((element) => element.profil_form), 1);
            }

            if(isFor === "mount"){
                this.setState({loading: false, result: result_tmp})
            } else {
                if (prevState.result) {
                    if (!isSame(prevState.result, result_tmp)) {
                        this.setState({loading: false, result: result_tmp})
                    }
                }else {
                    this.setState({loading: false, result: result_tmp})
                }
            }

        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        this.constructResult(prevState, "update")
        this.updateDimensions(prevState);

        redirect(window.location.href, RoutePathArray, prevProps);

        this.props.setRoutesInfo({
            PathIdentification: PathIdentification,
            PathUpdatePassword: PathUpdatePassword,
            RoutePathArray: RoutePathArray,
            PathToCart: PathToCart,
        })
    }

    componentDidMount() {
         window.addEventListener("resize", this.updateDimensions);

        //récupération des connecteurs
        this.props.requestGetConnectorsData();

        if (this.props.application.getApp.has_planning === '1'){
            this.props.requestGetPlanningsData();
        }
        if (this.props.application.getApp.has_market === '1'){
            this.props.requestGetMarketData();
        }

        this.constructResult(null, "mount")
    }

    render() {

        const routeComponents = this.createRoute(this.state.result, "", this.state.connected, true, this.props.market.result, this.props.plannings.result, this.props.announces);

        if (
            this.state.loading
            || (!this.state.result)
            || (this.props.application.getApp.has_market === '1' && !this.props.market.success)
            || (this.props.application.getApp.has_planning === '1' && !this.props.plannings.success)
        ) {
            return <ShapperLoader  />;
        }

        let paddingStyle = ((this.state.height > this.state.width || this.state.width <= 800) && (this.state.mode !== "included") ) ? "50px" : "0px" ;
        let maxWidth = this.props.application.getApp.webParams.content_max_width ? this.props.application.getApp.webParams.content_max_width + "px" : "";
        let innerStyle = this.state.isResponsive ? {paddingTop: paddingStyle, minHeight: this.state.height} : { margin : "0 auto", maxWidth : maxWidth, paddingTop: paddingStyle, minHeight: this.state.height };
        console.log(this.props.application.getApp);

        return (

            <React.Fragment>

                {this.props.application.getApp.key_wysistat ? (
                    <Helmet>
                        <script>
                            {`var _wsq = _wsq || [];_wsq.push(['_setNom', '`+this.props.application.getApp.key_wysistat+`']);_wsq.push(['_wysistat']);`}
                        </script>
                        <script src="https://www.wysistat.com/ws.jsa" type="text/javascript" async={true} />
                    </Helmet>
                ) : ""}

                {this.state.mode && this.state.mode === "included" ? "" : (
                    <NavBar
                        result={this.state.result}
                        connected={this.state.connected}
                    />
                )}

                <div id="app-body" style={maxWidth || this.props.application.type === "graphical_view" ? innerStyle : {...innerStyle, maxWidth: "1200px"}} >

                    <Switch>

                        {routeComponents}

                        <Route path="update_password" component={UpdatePassword} />
                        <Route
                            path="*"
                            render={(props) => (
                                <PageNotFound {...props} height={this.state.height} dataAuth={this.props.dataAuth}/>
                            )}
                        />

                    </Switch>

                </div>

                {(this.state.mode && this.state.mode === "included") || this.state.isResponsive ? "" : (
                    <Footer>
                        <Box mt={5} style={{marginTop: "0px"}}>
                            <Copyright title={this.props.application.getApp.title} />
                        </Box>
                    </Footer>
                ) }

                <PopUpLoader active={this.props.inner_app_loading} />

                <RedirectError isOpen={this.props.application.redirectError} onClose={() => {this.props.setRedirectError(false)}} />

            </React.Fragment>
        );
    }

    createRoute (contents, parentPath, connected, first, data_market, data_planning, data_announces) {
        let end = [];
        let baseurlparent = (parentPath !== "") ? parentPath + "/" : "/";

        if (parentPath === "") {
            let content = {title: "update_password"}
            //if(connected){

            end.push(
                <PropsRoute
                    exact
                    path={baseurlparent + urlrewriting("update_password")}
                    component={UpdatePassword}
                    //pageId={content.id}
                    dataPage={content}
                    isConnected={connected}
                    //key={key}
                />
            );
        }

        if (contents) {
            contents.forEach((content, key) => {
                if (content.title) {
                    let shouldBeRecursive = true;

                    switch (content.type) {
                        case "wallet":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={TagWallet}
                                    pageId={content.id}
                                    isConnected={connected}
                                    dataPage={content}
                                    key={key}
                                    width={this.state.width}
                                    height={this.state.height}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={TagWallet}
                                        pageId={content.id}
                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                        width={this.state.width}
                                        height={this.state.height}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "wallet_stripe":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={StripeWallet}
                                    pageId={content.id}
                                    isConnected={connected}
                                    dataPage={content}
                                    key={key}
                                    width={this.state.width}
                                    height={this.state.height}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={StripeWallet}
                                        pageId={content.id}
                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                        width={this.state.width}
                                        height={this.state.height}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "identification":
                            PathIdentification = baseurlparent + urlrewriting(content.title);

                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Identification}
                                    pageId={content.id}
                                    isConnected={connected}
                                    dataPage={content}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Identification}
                                        pageId={content.id}
                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "category_product":
                            if (data_market) {

                                let nodesTmp = constructNodeProduct(data_market, content)

                                if (nodesTmp.length > 0) {
                                    //stockage des produits dans nodes
                                    if (!isSame(sortArray(nodesTmp, "title"), sortArray(content.nodes, "title"))) {
                                        content.nodes = [];
                                        sortArray(nodesTmp, "title").forEach((product) => {

                                            //ajout d'information pour la construction future du composant produit

                                            product.product_id = product.id;
                                            product.parent_id = content.id;
                                            product.type = 'product';
                                            product.accessibility = content.accessibility;
                                            product.icon_name = "fa-shopping-bag";
                                            if (product.medias.length > 0) {
                                                product.preview_url = product.medias[0];
                                            }

                                            content.nodes.push(product)
                                        })
                                    }
                                }
                            }

                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Category}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    contents={contents}
                                    key={key}
                                    market={data_market}
                                />
                            );

                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Category}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}
                                        contents={contents}
                                        key={key}
                                        market={data_market}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "planning":
                            const events_for_planning = [];
                            let planning_children;
                            if (data_planning) {
                                let planning_selected = data_planning.plannings.find((element) => element.id === content.planning_id)

                                if (planning_selected) {
                                    content.display_type = planning_selected.display_type;
                                    planning_children = [planning_selected];

                                    //récupération des sous plannings
                                    data_planning.plannings.forEach((planning) => {
                                        if (planning.evt_planning_id && planning.evt_planning_id === planning_selected.id) {
                                            planning_children.push(planning)
                                        }
                                    })

                                    //récupération de tous les events et formatage pour planning
                                    planning_children.forEach((planning) => {
                                        planning.events_id.forEach((event) => {
                                            if (data_planning.events.find((element) => element.id === event)) {
                                                let current_event = data_planning.events.find((element) => element.id === event);

                                                current_event.accessibility = content.accessibility;

                                                //creation route + component event ----
                                                RoutePathArray[current_event.id] = {
                                                    url: "/" + urlrewriting(planning.title) + "/" + urlrewriting(current_event.title) + "-" + current_event.id,
                                                    title: current_event.title,
                                                    parentId: content.id,
                                                    type: current_event.type,
                                                    accessibility: current_event.accessibility
                                                }
                                                end.push(
                                                    <PropsRoute
                                                        exact
                                                        path={"/" + urlrewriting(planning.title) + "/" + urlrewriting(current_event.title) + "-" + current_event.id}
                                                        component={Event}
                                                        pageId={current_event.id}
                                                        dataPage={current_event}
                                                        isConnected={connected}

                                                        key={key}
                                                    />
                                                );
                                                // ------------------------------------

                                                // formatage event pour planning ------
                                                let new_event = createEventObject(current_event, planning);
                                                events_for_planning.push(new_event)
                                                // ------------------------------------
                                            }
                                        })
                                    })
                                }
                            }

                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Planning}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}

                                    key={key}
                                    events={Array.from(new Set(events_for_planning))}
                                    plannings={planning_children}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Planning}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}

                                        key={key}
                                        events={Array.from(new Set(events_for_planning))}
                                        plannings={planning_children}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "planning_mobinaute":
                            const events_for_planning_mobinaute = [];
                            const plannings_for_mobinaute = [];
                            if (data_planning) {
                                //récupération des events et des plannings puis formatage pour le planning
                                data_planning.my_planning.forEach((id_event) => {
                                    if (data_planning.events.find((element) => element.id === id_event)) {
                                        let current_event = data_planning.events.find((element) => element.id === id_event);

                                        if (data_planning.plannings.find((element) => element.id === current_event.evt_planning_id)) {
                                            let planning = data_planning.plannings.find((element) => element.id === current_event.evt_planning_id);

                                            current_event.accessibility = content.accessibility;

                                            //creation route + component event ----
                                            RoutePathArray[current_event.id] = {
                                                url: "/" + urlrewriting(planning.title) + "/" + urlrewriting(current_event.title) + "-" + current_event.id,
                                                title: current_event.title,
                                                parentId: content.id,
                                                type: current_event.type,
                                                accessibility: current_event.accessibility
                                            }
                                            end.push(
                                                <PropsRoute
                                                    exact
                                                    path={"/" + urlrewriting(planning.title) + "/" + urlrewriting(current_event.title) + "-" + current_event.id}
                                                    component={Event}
                                                    pageId={current_event.id}
                                                    dataPage={current_event}
                                                    isConnected={connected}

                                                    key={key}
                                                />
                                            );
                                            // ------------------------------------

                                            // formatage event pour planning ------
                                            let new_event = createEventObject(current_event, planning);
                                            events_for_planning_mobinaute.push(new_event)
                                            // ------------------------------------

                                            plannings_for_mobinaute.push(planning);
                                        }
                                    }
                                })
                            }

                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Planning}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                    events={Array.from(new Set(events_for_planning_mobinaute))}
                                    plannings={Array.from(new Set(plannings_for_mobinaute))}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Planning}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}

                                        key={key}
                                        events={Array.from(new Set(events_for_planning_mobinaute))}
                                        plannings={Array.from(new Set(plannings_for_mobinaute))}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "page":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Page}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Page}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}

                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "product":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Product}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    contents={contents}
                                    key={key}
                                    market={data_market}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Product}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}

                                        key={key}
                                        market={data_market}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "cart":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            PathToCart = baseurlparent + urlrewriting(content.title)
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Cart}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}

                                    contents={contents}
                                    key={key}
                                    market={data_market}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Cart}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}

                                        key={key}
                                        market={data_market}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "order_history":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={OrderHistory}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}

                                    contents={contents}
                                    key={key}
                                    market={data_market}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={OrderHistory}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}

                                        key={key}
                                        market={data_market}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "search":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Search}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    contents={contents}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Search}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}
                                        contents={contents}

                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "location":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Page}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}

                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Page}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "category":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Category}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Category}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}

                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "playlist":
                            shouldBeRecursive = false;

                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Playlist}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Playlist}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "graphical_view":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Graph}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                    allData={contents}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Graph}
                                        pageId={content.id}
                                        dataPage={content}
                                        isConnected={connected}
                                        key={key}
                                        allData={contents}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "pdf":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Pdf}
                                    pageId={content.id}
                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Pdf}
                                        pageId={content.id}

                                        dataPage={content}
                                        isConnected={connected}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "web":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Web}
                                    pageId={content.id}

                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Web}
                                        pageId={content.id}

                                        dataPage={content}
                                        isConnected={connected}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "video":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={video}
                                    pageId={content.id}

                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={video}
                                        pageId={content.id}

                                        dataPage={content}
                                        isConnected={connected}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "photo":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Photo}
                                    pageId={content.id}

                                    dataPage={content}
                                    isConnected={connected}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Photo}
                                        pageId={content.id}

                                        dataPage={content}
                                        isConnected={connected}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "form":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }
                            let formInputs, typeForm, displayForm, displayDraft, displayHistory, responses = "";

                            if (content.form) {

                                formInputs = content.form.inputs_form;

                                displayForm = content.display_form;
                                displayDraft = content.display_draft_form && content.form.is_draft_app;
                                displayHistory = content.display_historic_form && content.form.is_historical_app;

                                responses = content.form.responses;

                                typeForm = content.form.type_form;
                            }

                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Formulaire}
                                    typeForm={typeForm}
                                    Form={formInputs}
                                    displayForm={displayForm}
                                    displayDraft={displayDraft}
                                    displayHistory={displayHistory}
                                    responsesHistory={responses}
                                    pageId={content.id}
                                    isConnected={connected}
                                    dataPage={content}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Formulaire}
                                        typeForm={typeForm}
                                        displayForm={displayForm}
                                        displayDraft={displayDraft}
                                        displayHistory={displayHistory}
                                        responsesHistory={responses}
                                        pageId={content.id}
                                        Form={formInputs}
                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "form_announce":
                            if (data_market && data_market.form_announce) {

                                RoutePathArray[content.id] = {
                                    url: baseurlparent + urlrewriting(content.title),
                                    title: content.title,
                                    parentId: content.parent_id,
                                    type: content.type,
                                    accessibility: content.accessibility
                                }

                                let form = data_market.form_announce

                                content.form = form

                                end.push(
                                    <PropsRoute
                                        exact
                                        path={baseurlparent + urlrewriting(content.title)}
                                        component={Formulaire}
                                        typeForm={form.type_form}
                                        Form={form.inputs_form}
                                        pageId={content.id}
                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                    />
                                );
                                if (first) {
                                    end.push(
                                        <PropsRoute
                                            exact
                                            path={"/"}
                                            component={Formulaire}
                                            typeForm={form.type_form}
                                            Form={form.inputs_form}
                                            pageId={content.id}
                                            isConnected={connected}
                                            dataPage={content}
                                            key={key}
                                        />
                                    );
                                    first = false;
                                }

                            }

                            break;
                        case "list_announce":

                            if (data_announces.list) {

                                if (data_announces.list.length === 0) {
                                    content.nodes = [];
                                } else {
                                    //stockage des annonces dans nodes
                                    if (!isSame(sortArray(data_announces.list, "id"), sortArray(content.nodes, "id"))) {

                                        content.nodes = constructNodeAnnounce(content, data_announces.list, "announce", "list");

                                    }
                                }
                            }

                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }

                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Category}
                                    pageId={content.id}
                                    isConnected={connected}
                                    dataPage={content}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Category}
                                        pageId={content.id}
                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "list_announce_mobinaute":

                            if (data_announces.published_announces && data_announces.interested_announces) {

                                let published_announces = constructNodeAnnounce(content, data_announces.published_announces, "announce-published", "published")
                                let interested_announces = constructNodeAnnounce(content, data_announces.interested_announces, "announce-interested", "interested")

                                content.nodes = published_announces.concat(interested_announces);

                            }

                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }

                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={AnnounceMobinaute}
                                    pageId={content.id}
                                    isConnected={connected}
                                    dataPage={content}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={AnnounceMobinaute}
                                        pageId={content.id}
                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                    />
                                );
                                first = false;
                            }

                            break;
                        case "announce":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }

                            content.created_at_string = dateToLocaleString(content.created_at, this.props.t)

                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Announce}
                                    pageId={content.id}
                                    isConnected={connected}
                                    dataPage={content}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Announce}
                                        pageId={content.id}
                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        case "list_map":
                            RoutePathArray[content.id] = {
                                url: baseurlparent + urlrewriting(content.title),
                                title: content.title,
                                parentId: content.parent_id,
                                type: content.type,
                                accessibility: content.accessibility
                            }

                            end.push(
                                <PropsRoute
                                    exact
                                    path={baseurlparent + urlrewriting(content.title)}
                                    component={Category}
                                    pageId={content.id}
                                    isConnected={connected}
                                    dataPage={content}
                                    key={key}
                                />
                            );
                            if (first) {
                                end.push(
                                    <PropsRoute
                                        exact
                                        path={"/"}
                                        component={Category}
                                        pageId={content.id}

                                        isConnected={connected}
                                        dataPage={content}
                                        key={key}
                                    />
                                );
                                first = false;
                            }
                            break;
                        default:
                            break;
                    }
                    if (content.nodes && shouldBeRecursive) {
                        if (content.nodes.length > 0) {

                            let tab = this.createRoute(content.nodes, baseurlparent + urlrewriting(content.title), connected, false, this.props.market.result, this.props.plannings.result, this.props.announces);

                            end = end.concat(tab);
                        }
                    }
                }
            });
        }

        return end;
    }

}

//REDUX
const mapStateToProps = (state) => ({
    data: state.content,
    dataAuth : state.contentauth,
    dataSSO : state.contentauthSso,
    plannings: state.plannings,
    connectors: state.connectors,
    market: state.market,
    styleTab: state.style,
    profil: state.profil,
    application: state.application,
    announces: state.announces,
    inner_app_loading: state.loader
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetPlanningsData,
            requestGetMarketData,
            addMobinauteData,
            addProfilFormData,
            setRoutesInfo,
            setRedirectError,
            resetMobinauteData,
            setAppInnerSize,
            requestGetConnectorsData
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Home);
