export const WS_PROTOCOL = 'http://';
export const WS_PROTOCOL_HTTPS = 'https://';
export const WS_ENDPOINT = '/services/request';
export const TIMEOUT = 30000; //30sec
export const WS_GET_CONTENT = '/getContentApplication';
export const WS_GET_APPLICATION = '/getApplication'
export const WS_AUTH = '/authentification';
export const WS_UPDATE_PASSWORD = '/updatePassword';
export const WS_GET_SECRET_KEY_BY_DOMAIN_NAME = '/getSecretKeyByDomainName'
export const WS_GET_APP_COLOR = '/getAppColors'
export const WS_GET_APP_STYLES = '/getAppStyles'
export const WS_GET_APP_FONTS = '/getAppFonts'
export const WS_REGISTER_DEVICE = '/registerAppDeviceToken'
export const WS_PASSWORD_LOST = '/forgetPassword'
export const WS_REGISTER_MOBINAUTE = '/registerMobinaute'
export const WS_SET_RESPONSE_DYNAMIC_FORM = '/setResponseDynamicForm'
export const WS_GET_HISTORIC_RESPONSES = '/getHistoricResponses'
export const WS_GET_HISTORIC_VERSIONS = '/getHistoricVersions'
export const WS_SET_RESPONSE_PROFIL_FORM = '/setResponseProfilForm'
export const WS_UPLOAD_MEDIA_STATIC = '/uploadMediaStatic'
export const WS_AUTH_WITH_ACCESS_TOKEN = '/authWithAccessToken'
export const WS_GET_MARKET = '/getMarket'
export const WS_GET_PLANNINGS = '/getPlannings'
export const WS_GET_CONNECTORS = '/getConnectors'
export const WS_CREATE_ORDER = '/createOrder'
export const WS_ORDER_VALIDATION = '/orderValidation'
export const WS_JOIN_EVENT = '/joinEvent'
export const WS_LEAVE_EVENT = '/leaveEvent'
export const WS_DETACH_PM = '/detachPaymentMethod'
export const WS_DELETE_EVT_EVENT = '/deleteEvtEvent'
export const WS_SET_EVT_EVENT = '/setEvtEvent'
export const WS_GET_STRIPE_INFOS = '/getStripeInfoMobinaute'
export const WS_GET_TAGS = '/getTags'
export const WS_DETACH_TAG = '/detachTag'
export const WS_ATTACH_TAG = '/attachTag'
export const WS_TAG_TREATMENT = '/tagTreatment'
export const WS_SET_MARKET_ANNOUNCE = '/setMrktAnnounce'
export const WS_GET_MARKET_ANNOUNCES = '/getMrktAnnounces'
export const WS_SEND_ANNOUNCE_PROPOSITION = '/sendAnnounceProposition'
export const WS_GET_ANNOUNCE_PROPOSITIONS = '/getAnnouncePropositions'
export const WS_GET_ANNOUNCE_PROPOSITION_EXCHANGES = '/getAnnouncePropositionExchanges'
