import React from "react";
import "./Signature/sigCanvas.css";
import TextField from "@material-ui/core/TextField/TextField";
import {bindActionCreators} from "redux";
import {addVariableData, requestGetPlanningsData} from "../../actions";
import {connect} from "react-redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class FormulaireProfil extends React.Component{
    render() {
        const dyn_string  = this.props.t;
        let label = {};
        let errorProfil = {};
        let mobinaute = this.props.profil.mobinaute;

        Object.entries(this.props.formData).forEach(([res]) => {
            if(this.props.formState[res+"error"]){
                label[res] = this.props.formState[res+"error"]
                errorProfil[res] = true;
            }else{
                label[res] = res;
                errorProfil[res] = false;
            }
        })

        let display_password;
        if (parseInt(this.props.application.getApp.password_update) === 1) {
            display_password = (
                <>
                    <TextField
                        key={"ProfilForm_password"}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        autoComplete="password"
                        name="password"
                        type="password"
                        placeholder={dyn_string('profile_placeholder_password')}
                        value={this.props.formState.value}
                        onChange={this.props.handleChange}
                        label={dyn_string('profile_label_password')}
                        error={errorProfil["pass"]}
                    />
                    <span style={{color: "#999999", fontStyle: "italic"}}>{dyn_string('profile_alert_msg_info_update_password')}</span>
                    <TextField
                        key={"ProfilForm_confirm_password"}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="confirm_password"
                        autoComplete="confirm_password"
                        name="confirm_password"
                        type="password"
                        placeholder={dyn_string('profile_placeholder_password')}
                        value={this.props.formState.value}
                        onChange={this.props.handleChange}
                        label={dyn_string('profile_label_confirm_pass')}
                        error={errorProfil["pass"]}
                    />
                </>
            );
        }

        return (
            <>
                <TextField
                    key={"ProfilForm_firstname"}
                    aria-required={true}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    autoComplete="firstname"
                    name="firstname"
                    type="text"
                    placeholder={dyn_string('profile_label_fistname')}
                    defaultValue={mobinaute.firstname}
                    value={this.props.formState.value}
                    onChange={this.props.handleChange}
                    label={dyn_string('profile_label_fistname')}
                    error={errorProfil["firstname"]}
                />
                <TextField
                    key={"ProfilForm_lastname"}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    autoComplete="lastname"
                    name="lastname"
                    type="text"
                    placeholder={dyn_string('profile_label_lastname')}
                    defaultValue={mobinaute.lastname}
                    value={this.props.formState.value}
                    onChange={this.props.handleChange}
                    label={dyn_string('profile_label_lastname')}
                    error={errorProfil["lastname"]}
                />
                <TextField
                    key={"ProfilForm_login"}
                    variant="outlined"
                    margin="normal"
                    required
                    disabled
                    fullWidth
                    name="login"
                    type="text"
                    placeholder={dyn_string('profile_label_login')}
                    defaultValue={mobinaute.login}
                    value={this.props.formState.value}
                    onChange={this.props.handleChange}
                    label={dyn_string('profile_placeholder_login')}
                    error={errorProfil["login"]}
                />

                <TextField
                    key={"ProfilForm_email"}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="Email"
                    autoComplete="Email"
                    defaultValue={mobinaute.mail}
                    name="Email"
                    type="mail"
                    placeholder={dyn_string('profile_placeholder_mail')}
                    value={this.props.formState.value}
                    onChange={this.props.handleChange}
                    label={label["Email"]?label["Email"]:dyn_string('profile_label_mail')}
                    error={errorProfil["Email"]}
                />
                {display_password}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    variableStorage: state.variableStorage,
    styles: state.style,
    application: state.application,
    profil: state.profil,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addVariableData,
            requestGetPlanningsData,
        },
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(FormulaireProfil);
