import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import BreadCrumbs from "../../components/BreadCrumbs";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import "./page.css"
import PageBody from "./Page.Body";
import PageMedia from "./Page.Media";


class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const {dataPage} = this.props;

        return (
            <React.Fragment>

                <BreadCrumbs id={dataPage.id} />

                <div className="page-wrapper">

                    <div id="header-page-wrapper">
                        <PageMedia dataPage={dataPage} />
                    </div>

                    <div id="body-page">

                        <PageBody dataPage={dataPage} />

                        {this.props.children}

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    styles: state.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Page);
