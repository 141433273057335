
export const REQUEST_GETAUTHENTICATIONSSO_DATA = "REQUEST_GETAUTHENTICATIONSSO_DATA";
export const RECEIVE_GETAUTHENTICATIONSSO_DATA = "RECEIVE_GETAUTHENTICATIONSSO_DATA";
export const RESET_GETAUTHENTICATION_SSO_DATA = "RESET_GETAUTHENTICATION_SSO_DATA";

export const requestGetAuthenticationSSOData = () => ({
    type: REQUEST_GETAUTHENTICATIONSSO_DATA
});

export const receiveGetAuthenticationSSOData = data => ({
    type: RECEIVE_GETAUTHENTICATIONSSO_DATA,
    payload: data
});

export const resetAuthenticationSsoData = () => ({
    type: RESET_GETAUTHENTICATION_SSO_DATA
});
