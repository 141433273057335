import {
    SET_PAGE_TITLE,
    SET_PAGE_ID,
    SET_APP_SECRET_KEY,
    SET_APP_GET_APP,
    SET_APP_DEVICE,
    SET_APP_ID_SSO,
    INIT_APP_ERROR,
    SET_LANGUAGE_APP,
    SET_REMEMBER_ME,
    SET_REDIRECT_ERROR,
    SET_IS_IDENT_COMP,
    SET_APP_INNER_SIZE, SET_APP_SIZE,
    RESET_APP,
    RESET_APP_ERROR, SET_ELEMENT_TYPE, SET_DEVICE_TOKEN
} from "../actions/index";


const initState = {
    title: "",
    type: "",
    pageId: "",
    remember_me: false,
    language: "",
    secretKey: "",
    id_sso: "",
    deviceId: "",
    randomize: "",
    initAppError: {error:false, info:"", log:""},
    redirectError: false,
    getApp: null,
    isIdentComp:false,
    innerHeight: 0,
    innerWidth: 0,
    height: 0,
    width: 0
}

const application = (state= initState, action) => {
    switch (action.type) {
        case RESET_APP:
            return initState;
        case RESET_APP_ERROR:
            return {
                ...state,
                initAppError: {error:false, info:"", log:""}
            }
        case SET_PAGE_TITLE:
            return {
                ...state,
                title: action.payload.title
            };
        case SET_ELEMENT_TYPE:
            return {
                ...state,
                type: action.payload.type
            };
        case SET_IS_IDENT_COMP:
            return {
                ...state,
                isIdentComp: action.payload.isIdentComp
            };
        case SET_PAGE_ID:
            return {
                ...state,
                pageId: action.payload.id
            };
        case SET_APP_SECRET_KEY:
            return {
                ...state,
                secretKey: action.payload.secretKey
            };
        case SET_APP_GET_APP:
            return {
                ...state,
                getApp: action.payload,
                id_sso: action.payload && action.payload.connectorAuthSSOs ? action.payload.connectorAuthSSOs[0].idConnectorSSO : 0
            };
        case SET_APP_DEVICE:
            return {
                ...state,
                randomize: action.payload.randomize,
                deviceId: action.payload.deviceId,
                deviceToken: action.payload.deviceToken,
            };
        case SET_APP_INNER_SIZE:
            return {
                ...state,
                innerHeight: action.payload.innerHeight,
                innerWidth: action.payload.innerWidth
            };
        case SET_APP_SIZE:
            return {
                ...state,
                height: action.payload.height,
                width: action.payload.width
            };
        case SET_APP_ID_SSO:
            return {
                ...state,
                id_sso: action.payload.id_sso
            };
        case INIT_APP_ERROR:
            return {
                ...state,
                initAppError: {error: true, info: action.payload.info, log: action.payload.data},
            }
        case SET_LANGUAGE_APP:
            return {
                ...state,
                language: action.payload,
            }
        case SET_REMEMBER_ME:
            return {
                ...state,
                remember_me: action.payload,
            }
            case SET_REDIRECT_ERROR:
            return {
                ...state,
                redirectError: action.payload,
            }
        default:
            return state
    }
}

export default application;
