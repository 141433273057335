import React, {useState} from "react";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {CheckoutForm} from './Stripe';
import "./stripe.css"

export const StripeInitializer = (props) => {

    const [stripePromise] = useState(() => loadStripe(props.stripeData.publicKey))

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm {...props}/>
        </Elements>
    );
}
