import React from "react";
import "./Signature/sigCanvas.css";
import TextField from "@material-ui/core/TextField/TextField";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {Grid} from "@material-ui/core";

class FormulaireEventUpdate extends React.Component{
    render() {

        const dyn_string  = this.props.t;
        
        return (
            <>
                <TextField
                    key={"EventUpdate_title"}
                    aria-required={true}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="evt_update_title"
                    name="evt_update_title"
                    type="text"
                    placeholder={dyn_string('planning_label_event_title')}
                    value={this.props.formState.evt_update_title || ""}
                    onChange={this.props.handleChange}
                    label={dyn_string('planning_label_event_title')}
                />

                <TextField
                    key={"EventUpdate_description"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={4}
                    id={"evt_update_description"}
                    name={"evt_update_description"}
                    type="text"
                    placeholder={dyn_string('planning_label_event_description')}
                    value={this.props.formState.evt_update_description || ""}
                    onChange={this.props.handleChange}
                    label={dyn_string('planning_label_event_description')}
                />

                <TextField
                    key={"EventUpdate_startDate"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={"evt_update_start_date"}
                    name={"evt_update_start_date"}
                    type="date"
                    value={this.props.formState.evt_update_start_date || ""}
                    onChange={this.props.handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={"Date de début"}
                />

                <TextField
                    key={"EventUpdate_startTime"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={"evt_update_start_time"}
                    name={"evt_update_start_time"}
                    type="time"
                    value={this.props.formState.evt_update_start_time || ""}
                    onChange={this.props.handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    label={"Heure de début"}
                />

                <TextField
                    key={"EventUpdate_endDate"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={"evt_update_end_date"}
                    name={"evt_update_end_date"}
                    type="date"
                    value={this.props.formState.evt_update_end_date || ""}
                    onChange={this.props.handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={"Date de fin"}
                />

                <TextField
                    key={"EventUpdate_endTime"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id={"evt_update_end_time"}
                    name={"evt_update_end_time"}
                    type="time"
                    value={this.props.formState.evt_update_end_time || ""}
                    onChange={this.props.handleChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    label={"Heure de fin"}
                />

                <div key={"EventUpdate_address"}>
                    <Grid container spacing={3} style={{marginTop: "8px"}}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="address"
                                fullWidth
                                id="evt_update_address_street1"
                                label={dyn_string('form_label_address_address1')}
                                name={'evt_update_address_street1'}
                                placeholder={dyn_string('form_label_address_address1')}
                                value={this.props.formState.evt_update_address_street1 || ""}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="address"
                                fullWidth
                                id="evt_update_address_street2"
                                label={dyn_string('form_label_address_address2')}
                                name={'evt_update_address_street2'}
                                placeholder={dyn_string('form_label_address_address2')}
                                value={this.props.formState.evt_update_address_street2 || ""}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="evt_update_address_city"
                                variant="outlined"
                                type="address"
                                name={'evt_update_address_city' }
                                label={dyn_string('form_label_address_city')}
                                fullWidth
                                placeholder={dyn_string('form_label_address_city')}
                                value={this.props.formState.evt_update_address_city || ""}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="address"
                                id="evt_update_address_zip"
                                variant="outlined"
                                name={'evt_update_address_zip'}
                                label={dyn_string('form_label_address_zip')}
                                fullWidth
                                placeholder={dyn_string('form_label_address_zip')}
                                value={this.props.formState.evt_update_address_zip || ""}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="address"
                                id="evt_update_address_country"
                                variant="outlined"
                                name={'evt_update_address_country'}
                                label={dyn_string('form_label_address_country')}
                                fullWidth
                                placeholder={dyn_string('form_label_address_country')}
                                value={this.props.formState.evt_update_address_country || ""}
                                onChange={this.props.handleChange}
                            />
                        </Grid>
                    </Grid>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    variableStorage: state.variableStorage,
    styles: state.style,
    application: state.application,
    profil: state.profil,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(FormulaireEventUpdate);
