import React from "react";
import {Link} from "react-router-dom";
import {Dialog, Grid, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import 'font-awesome/css/font-awesome.min.css';
import { connect } from "react-redux";
import {deleteArticle, purgeCart, requestGetMarketData} from "../../../actions";
import {prettyPrice} from "../../../helpers/Tools";
import Formulaire from "../../Formulaire/Formulaire";
import Container from "reactstrap/es/Container";
import {faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createOrder, orderValidation} from "../../../api/api";
import {Loader} from "semantic-ui-react";
import {bindActionCreators, compose} from 'redux';
import { withTranslation } from 'react-i18next';
import {CartProduct} from "./Cart.Product";
import {CartCheckout} from "./Cart.Checkout";
import {CartFormResponse} from "./Cart.Form.Response";
import "./cart.css"
import {ShapperButton} from "../../../components/ShapperButton/ShapperButton";

class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayForm: false,
            displayOrderSummary: false,
            displayErrorCreateOrder: false,
            loader: false,
            blur: 1,
            connected_to_purchase: false,
            validate_order: false,
            allowUpdate: true
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.setCreateOrder = this.setCreateOrder.bind(this);
        this.validateOrder = this.validateOrder.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.terminateOrder = this.terminateOrder.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {

        let products = this.props.products;

        products.forEach((product) => {
            this.setState({
                [product.variation.id]: product.quantity,
            })
        })
    }

    render() {
        console.log(" %c ////////CONTENU-PANIER////////",  'background: #222; color: #bada55');
        const dyn_string  = this.props.t;
        let products = this.props.products;
        let attributAll = this.props.market.attributs;
        let tmpTabAttributsName = [];
        let totalPriceTab = [0]; //initialisation de l'array avec 0 en valeur initiale pour reducer qui calcule la somme des éléments dans le tab
        let reducer = (accumulator, currentValue) => accumulator + currentValue; //calcul la somme des prix dans totalPriceTab
        let formInfo = {};
        let productsToBuy = []; //construction d'un tableau de produits pour envoi au WS apres remplissage du form de validation

        //récupération des titres des attributs présent dans la variation choisie
        if (products.length > 0){

            products.forEach((product) => {
                tmpTabAttributsName = [];
                product.variation.attributValues.forEach((variation_attribut) => {
                    attributAll.forEach((attribut) => {
                        for (let attribut_value of attribut.attributValues){
                            if (variation_attribut === attribut_value.id){
                                tmpTabAttributsName.push(attribut_value.title);
                                break;
                            }
                        }
                    })
                })
                product.attributs_title = tmpTabAttributsName.join(' - ');
            })

            let connected_to_purchase = (
                <Dialog
                    open={this.state.connected_to_purchase}
                    onClose={() => this.setState({connected_to_purchase: false})}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{dyn_string('profile_alert_title_info_warning')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dyn_string('market_alert_msg_info_validate_order_connection_error')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ShapperButton onClick={() => this.setState({connected_to_purchase: false})}>
                            {dyn_string('g_btn_ok')}
                        </ShapperButton>

                        <Link to={{pathname: this.props.routes.PathIdentification, state: {id: this.props.dataPage.id}}}>
                            <ShapperButton>
                                {dyn_string('auth_btn_login')}
                            </ShapperButton>
                        </Link>
                    </DialogActions>
                </Dialog>
            );

            let validate_order = (
                <Dialog
                    open={this.state.validate_order}
                    onClose={() => this.setState({validate_order: false})}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{dyn_string('market_alert_msg_succ_validate_order')}</DialogTitle>
                    <DialogActions>
                        <ShapperButton onClick={() => this.terminateOrder()}>
                            {dyn_string('market_btn_continue')}
                        </ShapperButton>
                    </DialogActions>
                </Dialog>
            );

            let display_error_create_order = (
                <Dialog
                    open={this.state.displayErrorCreateOrder}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{dyn_string('market_alert_msg_err_validate_order')}</DialogTitle>
                    <DialogActions>
                        <ShapperButton onClick={() => this.setState({displayErrorCreateOrder: false, blur: !this.state.displayOrderSummary? 1 : 0})}>
                            {dyn_string('market_btn_continue')}
                        </ShapperButton>
                    </DialogActions>
                </Dialog>
            )

            if(this.state.displayForm){

                formInfo.id = this.props.dataPage.id;
                formInfo.accessibility = this.props.dataPage.accessibility;

                let currentSuppliersId = null;

                products.forEach((product) => {
                    if(currentSuppliersId != null && currentSuppliersId != product.product.supplier_id){
                        //multi suppliers
                    }
                    else {
                        currentSuppliersId = product.product.supplier_id;
                    }
                    productsToBuy.push({id: product.variation.id, product_id: product.product.id, qty: this.state[product.id]})
                })

                console.log("currentSuppliersId => " + currentSuppliersId);

                if(currentSuppliersId != null){
                    console.log("#1");

                    let supplier = this.props.market.suppliers.find((element) => element.id === currentSuppliersId);

                    formInfo.syn_content_redirect = supplier.form.syn_content_redirect; //gerer ça dans terminateOrder
                    formInfo.title = supplier.form.title;
                    formInfo.label_validation_button = supplier.form.label_validation_button;
                    formInfo.formulaire_id = supplier.form.id;
                    formInfo.inputs_form = supplier.form.inputs_form

                    console.log(formInfo);
                }
                else {
                    console.log("#2");
                    formInfo.syn_content_redirect = this.props.market.form.syn_content_redirect; //gerer ça dans terminateOrder
                    formInfo.title = this.props.market.form.title;
                    formInfo.label_validation_button = this.props.market.form.label_validation_button;
                    formInfo.formulaire_id = this.props.market.form.id;
                    formInfo.inputs_form = this.props.market.form.inputs_form

                    console.log(formInfo);
                }

            }

            return (
                <div style={{height: this.props.application.innerHeight}}>
                    <Loader active={this.state.loader} size='large' />

                    <Grid container spacing={3}>
                        <Grid item lg={this.state.allowUpdate ? 12 : 6} md={this.state.allowUpdate ? 12 : 6} sm={12} xs={12}>
                            <div style={{opacity: this.state.blur}}>
                                {this.state.displayOrderSummary ?
                                    (
                                        <div style={{padding:"16px"}}>
                                            <h1>{dyn_string('market_label_price_ttc_euro', {total_price: prettyPrice(parseFloat(this.state.order.amount_ttc).toFixed(2))})}</h1>

                                            <Typography>
                                                {dyn_string('market_label_price_ht_euro', {price: prettyPrice(parseFloat(this.state.order.amount_ht).toFixed(2))})}
                                                <span style={{margin: "0 8px"}}>|</span>
                                                {dyn_string('market_label_price_tva_euro', {tva: prettyPrice((parseFloat(this.state.order.amount_ttc)-parseFloat(this.state.order.amount_ht)).toFixed(2))})}
                                            </Typography>

                                            <CartFormResponse order={this.state.order} />
                                        </div>
                                    ) : ""
                                }

                                <div style={{textAlign:'center'}}>
                                    {products.map((product) => {
                                        if (this.state[product.variation.id]){
                                            let price_ttc = prettyPrice((product.variation.price_ttc*this.state[product.variation.id]).toFixed(2));
                                            let url_image = product.variation.medias.length > 0 ? product.variation.medias[0] : product.product.medias[0] ;
                                            totalPriceTab[product.variation.id] = product.variation.price_ttc*this.state[product.variation.id];

                                            return (
                                                <CartProduct
                                                    product={product}
                                                    url_image={url_image}
                                                    price_ttc={price_ttc}
                                                    value={this.state[product.variation.id]}
                                                    variationId={product.variation.id}
                                                    handleChange={this.handleChange}
                                                    handleDelete={this.handleDelete}
                                                    key={product.variation.id}
                                                    allowUpdate={this.state.allowUpdate}
                                                    displayOrderSummary={this.state.displayOrderSummary}
                                                />
                                            );
                                        } else return "";
                                    })}
                                </div>

                                {this.state.allowUpdate ?
                                    (
                                        <div style={{padding:"16px"}}>
                                            <h1>{dyn_string("market_label_price_ttc_euro", {total_price: prettyPrice(totalPriceTab.reduce(reducer).toFixed(2))})}</h1>

                                            <div style={{textAlign: "center"}}>
                                                <ShapperButton onClick={() => this.handleClick()}>
                                                    {dyn_string('market_btn_order')}
                                                </ShapperButton>
                                            </div>

                                        </div>
                                    ) : ""
                                }
                            </div>
                        </Grid>
                        <Grid
                            item lg={6} md={6} sm={12} xs={12}
                            style={this.state.allowUpdate ? {display: "none"} : {display: "", height:this.props.application.innerHeight}}
                            className={this.state.loader || this.state.blur === 0 ? "" : "Grid"}
                        >
                            {this.state.displayForm ?
                                (
                                    <Container component="main" style={{opacity: this.state.blur}}>
                                        <Formulaire
                                            typeForm={"market"}
                                            Form={formInfo.inputs_form}
                                            styles={this.props.styles}
                                            formInfo={formInfo}
                                            products={productsToBuy}
                                            createOrder={this.setCreateOrder}
                                        >
                                            <ShapperButton
                                                onClick={() => this.cancelOrder()}
                                                fullWidth
                                                style={{opacity: "0.8", marginBottom:"80px"}}
                                            >
                                                {dyn_string('form_btn_product_cancel')}
                                                <FontAwesomeIcon
                                                    icon={faUndoAlt}
                                                    style={{ marginLeft: "10px"}}
                                                />
                                            </ShapperButton>
                                        </Formulaire>
                                    </Container>
                                ) : ""
                            }

                            {this.state.displayOrderSummary ?
                                (
                                    <CartCheckout order={this.state.order} validateOrder={this.validateOrder} cancelOrder={this.cancelOrder} handleBlur={this.handleBlur}/>
                                ) : ""
                            }

                        </Grid>
                    </Grid>

                    {/* popup */}
                    {connected_to_purchase}
                    {display_error_create_order}
                    {validate_order}
                </div>
            );
        } else {
            return (
                <div style={{textAlign:'center',height: this.props.application.innerHeight-250}}>
                    <h4>{dyn_string('market_label_no_result')}</h4>
                </div>
            );
        }
    }

    async handleChange(product) {
        await this.setState({
            [product.name]: product.value
        })
    }

    handleDelete(variationId) {
        this.props.deleteArticle(variationId);
    }

    async handleClick(){
        if (this.props.isConnected){
            if(this.props.market.form.inputs_form){
                await this.setState({
                    displayForm: true,
                    allowUpdate: false,
                })
            } else {
                let productsToBuy = [];

                this.props.products.forEach((product) => {
                    productsToBuy.push({id: product.variation.id, product_id: product.product.id, qty: this.state[product.id]})
                })

                await this.setState({
                    displayForm: false,
                })

                await this.setCreateOrder({products: productsToBuy})
            }
        } else {
            await this.setState({
                connected_to_purchase: true
            })
        }
    }

    async setCreateOrder(data){
        let objectToCreateOrder = {
            order: {
                products: data.products,
                responses: data.FormData ? data.FormData.responses : []
            },
            mobinauteId: data.FormData ? data.FormData.mobinauteId : this.props.profil.mobinaute.id,
            appId: data.FormData ? data.FormData.appId : this.props.application.getApp.id
        }
        this.setState({
            loader: true,
            blur: 0,
        })

        await  createOrder(objectToCreateOrder).then(res => {
            if (res.data.success){
                this.setState({
                    displayForm: false,
                    displayOrderSummary: true,
                    allowUpdate: false,
                    loader: false,
                    blur: 1,
                    order: res.data.result,
                })
            } else {
                this.setState({
                    displayForm: false,
                    displayOrderSummary: false,
                    loader: false,
                    blur: 1,
                    displayErrorCreateOrder: true,
                    allowUpdate: true
                })
            }
        }).catch(e => {
            console.log(e)
            this.setState({
                displayForm: false,
                displayOrderSummary: false,
                loader: false,
                blur: 1,
                displayErrorCreateOrder: true,
                allowUpdate: true
            })
        });
    }

    async validateOrder(data){
        let objectToValidateOrder = {
            orderId: data.id,
            mobinauteId: this.props.profil.mobinaute.id,
            appId: this.props.application.getApp.id
        }

        this.setState({
            loader: true,
            blur: 3,
        })

        await orderValidation(objectToValidateOrder).then(res => {
            if (res.data.success){
                this.setState({
                    validate_order: true,
                    loader: false,
                    blur: 3,
                });
            } else {
                this.setState({
                    loader: false,
                    blur: 3,
                    displayErrorCreateOrder: true,
                })
            }
        });
    }

    async cancelOrder(){
        await this.setState({ displayForm: false, displayOrderSummary: false, blur: 1, allowUpdate: true})
    }

    terminateOrder(){
        this.setState({validate_order: false, blur:0});

        this.props.purgeCart();
        this.props.requestGetMarketData();
    }

    async handleBlur(isBlur){
        await this.setState({blur: isBlur ? 0 : 1})
    }
}

const mapStateToProps = (state) => ({
    products: state.cart.items,
    styles: state.style,
    application: state.application,
    routes: state.routes,
    profil: state.profil
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteArticle,
            purgeCart,
            requestGetMarketData
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Cart);
