import {isResponsive} from "../../helpers/Tools";
import React from "react";
import NavBarMobile from "./NavBarMobile/NavBar.Mobile";
import NavBar from "./NavBar";

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    // Récupération de la taille de la fenetre au lancement du composant
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        if (isResponsive(this.state.width)) {

            return (
                <NavBarMobile result={this.props.result} connected={this.props.connected} />
            );
        } else {

            return (
                <NavBar result={this.props.result} connected={this.props.connected} />
            );
        }
    }
}

export default Index;
