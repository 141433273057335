import React from "react";
import {buildShapperPath} from "../../../helpers/Tools";
import {Avatar, Divider, List, ListItem, ListItemAvatar} from "@material-ui/core";
import {useSelector} from "react-redux";
import OpenItem from "../../../helpers/OpenItem";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


export const CategoryList = (props) => {

    const style = useSelector(state => state.style);

    return (
        <React.Fragment>

            {props.dataPage.nodes.map((content, key) => {

                return (

                    <OpenItem
                        key={key}
                        contentToRedirect={content.id}
                        isFor={'category'}
                    >
                        <List>

                            <ListItem button >

                                <ListItemAvatar>

                                    {content.preview_url ? (
                                        <Avatar src={buildShapperPath(content.preview_url)} style={{maxWidth:'100%'}} variant="rounded" />
                                    ) : (
                                        <Avatar style={{maxWidth:'100%', backgroundColor: "white", color: style.button.backgroundColor}} variant="rounded" >
                                            {content.icon_name ? (
                                                <div className={"fa "+content.icon_name} style={{fontSize:"2rem"}}/>
                                            ) : (
                                                <div className={"fa fa-arrow-circle-right"} style={{fontSize:"2rem"}}/>
                                            )}
                                        </Avatar>
                                    )}
                                </ListItemAvatar>

                                <div className="list-body-wrapper">

                                    <div className="list-body">

                                        <span className="title">{content.title}</span>

                                        {props.dataPage.category_type === "list_subtitle" && (
                                            <span className="sub-info">{content.subtitle}</span>
                                        )}
                                    </div>

                                    <ArrowForwardIosIcon style={{color: "#8191a0", fontSize:"1rem"}} />

                                </div>

                            </ListItem>

                        </List>

                        <Divider variant="middle" />
                    </OpenItem>
                )
            })}

        </React.Fragment>
    )
}
