import React from "react";
import Container from "reactstrap/es/Container";
import IdentificationFormulaire from "./Mobinaute.Identification.Formulaire";
import Formulaire from "../../Formulaire/Formulaire";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

/**
 * Composant Mobinaute, vérification si l'utilisateur est connecté, si oui affichage du profil, si non affichage des formulaires
*/
class MobinauteIdentification extends React.Component {

    render() {
        
        if (this.props.isConnected) {
            return (
                <Container style={{marginTop:"10px"}}>
                    <div className="content">
                        <Formulaire
                            typeForm={"ProfilForm"}
                            Form={this.props.profil.profil_form.inputs_form}
                        />
                    </div>
                </Container>
            );
        } else {
            return (
                <IdentificationFormulaire
                    {...this.props}
                    Form={this.props.profil.profil_form.inputs_form}
                />
            );
        }
    }
}

const mapStateToProps = (state) => ({
    application: state.application,
    profil: state.profil
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MobinauteIdentification);
