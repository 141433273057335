import {
    RECEIVE_GETAUTHENTICATIONSSO_DATA,
    REQUEST_GETAUTHENTICATIONSSO_DATA, RESET_GETAUTHENTICATION_SSO_DATA,
} from "../actions/index";

const contentauthSso =  (state = {loading:false}, action) => {
    switch (action.type) {
        case REQUEST_GETAUTHENTICATIONSSO_DATA:
            return {
                loading: true
            }
        case RECEIVE_GETAUTHENTICATIONSSO_DATA:
            return action.payload;
        case RESET_GETAUTHENTICATION_SSO_DATA:
            return {
                loading: false
            };
        default:
            return state;
    }
};

export default contentauthSso
