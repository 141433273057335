import React from "react";
import {calcAdaptiveVue, typeUrl} from "./GraphicalView.functions";
import {OneDisplay} from "./OneDisplay/OneDisplay";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Carousel} from "./Carousel/Carousel";
import {buildShapperPath} from "../../helpers/Tools";

class Graph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    render() {
        console.log(" %c ////////CONTENU-GRAPH////////", 'background: #222; color: #bada55');

        let json_content = JSON.parse(this.props.dataPage.content_tmp);
        let queries = {};
        let tmp;
        let lastTmp;
        let lastKey;

        let landscape_sort = Object.entries(json_content.landscape).sort(function(a,b) {
            return a[1].width - b[1].width;
        }); // trie du tab pour que le plus petit soit traité en premier

        //Création des QUERIES LANDSCAPE
        landscape_sort.forEach(([key, value], i) => {

            let orientation;
            if (Object.entries(json_content.portrait).length === 0) {
                orientation = "screen";
            } else {
                orientation = "(orientation: landscape)";
            }

            if (Object.entries(json_content.landscape).length === 2) {
                if (tmp) {
                    let calc = (parseInt(tmp) + parseInt(value.width)) / 2;
                    queries[lastKey] = orientation + " and (max-width:" + (calc - 1) + "px)";
                    queries[key] = orientation + " and (min-width:" + calc + "px)";
                }
                lastKey = key;
                tmp = value.width;
            } else if (Object.entries(json_content.landscape).length !== 1) {

                if (tmp) {
                    let calc = (parseInt(tmp) + parseInt(value.width)) / 2;
                    if (i === 1) {
                        queries[lastKey] = orientation + " and (max-width:" + calc + "px)";
                        lastKey = key;
                    } else if (i + 1 === Object.entries(json_content.landscape).length) {
                        queries[lastKey] = orientation + " and (min-width:" + (lastTmp + 1) + "px) and (max-width: " + calc + "px)";
                        queries[key] = orientation + " and (min-width:" + (calc + 1) + "px)";
                    } else {
                        queries[lastKey] = orientation + " and (min-width:" + (lastTmp + 1) + "px) and (max-width: " + calc + "px)";
                        lastKey = key;
                    }
                    lastTmp = calc;
                }
                lastKey = key;
                tmp = value.width;
            } else {
                if (Object.entries(json_content.portrait).length === 0) {
                    queries[key] = "screen";
                } else {
                    queries[key] = "(orientation: landscape)";
                }
            }
        });

        let portrait_sort = Object.entries(json_content.portrait).sort(function(a,b) {
            return b[1].width - a[1].width;
        }); // trie du tab pour que le plus grand soit traité en premier

        //Création des QUERIES PORTRAIT
        portrait_sort.forEach(([key, value], i) => {
            let orientation;
            if (Object.entries(json_content.landscape).length === 0) {
                orientation = "screen";
            } else {
                orientation = "(orientation: portrait)";
            }

            if(Object.entries(json_content.portrait).length === 2 && orientation === "screen")
            {
                if (tmp) {
                    let calc = (parseInt(tmp) + parseInt(value.width)) / 2;

                    queries[lastKey] = orientation + " and (max-width:" + calc + "px)";
                    queries[key] = orientation + " and (min-width:" + (calc - 1) + "px)";
                }
                lastKey = key;
                tmp = value.width;
            }else if (Object.entries(json_content.portrait).length === 2) {
                if (tmp) {
                    let calc = (parseInt(tmp) + parseInt(value.width)) / 2;

                    queries[lastKey] = orientation + " and (min-width:" + calc + "px)";
                    queries[key] = orientation + " and (max-width:" + (calc - 1) + "px)";
                }
                lastKey = key;
                tmp = value.width;
            } else if (Object.entries(json_content.portrait).length !== 1) {
                if (tmp) {
                    let calc = (parseInt(tmp) + parseInt(value.width)) / 2;
                    if (i === 1) {
                        queries[lastKey] = orientation + " and (min-width:" + calc + "px)";
                        lastKey = key;
                    } else if (i + 1 === Object.entries(json_content.portrait).length) {
                        queries[lastKey] = orientation + " and (min-width:" + calc + "px) and (max-width: " + (lastTmp - 1) + "px)";
                        queries[key] = orientation + " and (max-width:" + (calc - 1) + "px)";
                    } else {
                        queries[lastKey] = orientation + " and (min-width:" + calc + "px) and (max-width: " + (lastTmp - 1) + "px)";
                        lastKey = key;
                    }
                    lastTmp = calc;
                }
                lastKey = key;
                tmp = value.width;
            } else {
                if (Object.entries(json_content.landscape).length === 0) {
                    queries[key] = "screen";
                } else {
                    queries[key] = "(orientation: portrait)";
                }
            }
        });

        //Json_Content = portrait + lanscape (format pour gérer les vue graphique)
        json_content = Object.assign(json_content.portrait, json_content.landscape);

        const TabMatch = {};

        //Création des queries
        Object.entries(queries).forEach(
            (res, key) => (TabMatch[res[0]] = window.matchMedia(res[1]).matches)
        );

        return (
            <React.Fragment>
                {Object.entries(json_content).map(([key, jsongraph], i) => {

                    if (jsongraph !== null) {

                        let widthAdaptive = jsongraph.horizontal_display === "adjust";
                        let heightAdaptive = jsongraph.vertical_display === "adjust";

                        let width;
                        let height;

                        if (widthAdaptive) {

                            let max_width = this.props.application.getApp.webParams.content_max_width;

                            width = (max_width && this.props.application.innerWidth >= max_width) ? parseFloat(max_width) : this.props.application.innerWidth;
                        } else {
                            width = jsongraph.width + "px";
                        }

                        if (heightAdaptive) {
                            height =this.props.application.innerHeight;
                        } else {
                            height = jsongraph.height + "px";
                        }

                        let containerStyle = {
                            margin: "0 auto",
                            width: width,
                            height: height,
                            position: "sticky",
                            backgroundColor: jsongraph.bg_color,
                        }

                        /*if(jsongraph.horizontal_display_alignment === "center"){
                            containerStyle = {
                                ...containerStyle,
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)"
                            }
                        }

                        if(jsongraph.vertical_display_alignment === "center"){
                            containerStyle = {
                                ...containerStyle,
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)"
                            }
                        }*/

                        if (jsongraph) {
                            return (
                                TabMatch[key] && (
                                    <div
                                        key={key}
                                        style={containerStyle}
                                    >

                                        {jsongraph.zones.map((res, key) => {
                                            if (res.type === "one_display"){
                                                let myWidth = calcAdaptiveVue(res.width, jsongraph.width, width, widthAdaptive)
                                                let myHeight = calcAdaptiveVue(res.height, jsongraph.height, height, heightAdaptive)
                                                let myX = calcAdaptiveVue(res.position_x, jsongraph.width, width, widthAdaptive)
                                                let myY = calcAdaptiveVue(res.position_y, jsongraph.height, height, heightAdaptive)
                                                return (
                                                    <OneDisplay
                                                        key={key}
                                                        src={buildShapperPath(res.image)}
                                                        x={myX ? myX : 0}
                                                        y={myY ? myY : 0}
                                                        width={myWidth ? myWidth : 0}
                                                        height={myHeight ? myHeight : 0}
                                                        link={res.link}
                                                        typeLink={typeUrl(res.link)}
                                                        data={res}
                                                        style={this.props.styles}
                                                        parentId={this.props.pageId}
                                                        widthAdaptive={widthAdaptive}
                                                        heightAdaptive={heightAdaptive}
                                                    />
                                                );
                                            } else if (res.type === "slider_horizontal") {
                                                return (
                                                    <Carousel
                                                        key={key}
                                                        x={calcAdaptiveVue(res.position_x, jsongraph.width, width, widthAdaptive)}
                                                        y={calcAdaptiveVue(res.position_y, jsongraph.height, height, heightAdaptive)}
                                                        width={calcAdaptiveVue(res.width, jsongraph.width, width, widthAdaptive)}
                                                        height={calcAdaptiveVue(res.height, jsongraph.height, height, heightAdaptive)}
                                                        data={res}
                                                        allData={this.props.allData}
                                                        parentId={this.props.pageId}
                                                    />
                                                );

                                            } else return "";
                                        })}
                                    </div>
                                )
                            );
                        } else return "";
                    } else return "";
                })}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    styles: state.style,
    application: state.application,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
