import React from "react";
import {buildShapperPath, getAllContents} from "../../helpers/Tools";
import {TextField, List, ListItem, Divider, ListItemText, ListItemAvatar, Avatar} from "@material-ui/core";
import 'font-awesome/css/font-awesome.min.css';
import OpenItem from "../../helpers/OpenItem";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input_value: '',
            width: window.innerWidth,
            height: window.innerHeight,
            ALL_CONTENTS: [],
        };

        this.handleChange = this.handleChange.bind(this);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

        this.setState({ALL_CONTENTS: getAllContents([].concat(this.props.contents))})
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;

        this.setState({input_value: value});
    }

    render() {

        let resultTab = [];
        let stringWhereSearch = "";
        let full_input = this.state.input_value.split(" ");
        let full_regex = [];
        let isHere = false;
        const dyn_string = this.props.t

        full_input.forEach((content) => {
            if(content){
                full_regex.push(new RegExp(content, "i"));
            }
        });

        if (this.state.input_value !== ''){
            this.state.ALL_CONTENTS.forEach((content) => {
                for (let regex of full_regex){
                    if (stringWhereSearch.concat(' ', content.title, content.subtitle, content.content, content.keyword).search(regex) !== -1){
                        isHere = true;
                    } else {
                        isHere = false;
                        break;
                    }
                }

                if (isHere){
                    resultTab.push(content);
                }
            });
        }

        const styleSearch = {
            small: {
                height : "auto",
            },
            big: {
                height: this.state.height-250,
            },
        }

            let style = (resultTab.length > 6) ? styleSearch.small : styleSearch.big;

            // ------------------------------------------------------------------
            return (
                <div style={style}>
                    <TextField
                        aria-required={true}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="search"
                        name="search"
                        type="text"
                        label={dyn_string('search_title_search')}
                        placeholder={dyn_string('search_placeholder_your_search')}
                        onChange={this.handleChange}
                        value={this.state.value}
                    />
                    {resultTab.map((res, key) => {
                        if (res.title) {

                        let full_className = "";

                        if (res.preview_url){
                            return (
                                <OpenItem
                                    key={key}
                                    contentToRedirect={res.id}
                                >
                                    <List>
                                        <ListItem button >
                                            <ListItemAvatar>
                                                <Avatar src={buildShapperPath(res.preview_url)} style={{maxWidth:'100%'}}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={res.title}
                                                style={{ color:'black' }}
                                            />
                                        </ListItem>
                                    </List>
                                    <Divider variant="middle" />
                                </OpenItem>
                            );
                        } else {
                            if (res.icon_name){
                                full_className = "fa ".concat(res.icon_name); //FontAwesome
                            } else {
                                full_className = "fa fa-arrow-circle-right"; //FontAwesome default
                            }
                            return (
                                <OpenItem
                                    key={key}
                                    contentToRedirect={res.id}
                                >
                                    <List>
                                        <ListItem button >
                                            <ListItemAvatar>
                                                <Avatar style={{backgroundColor: this.props.styles.button.backgroundColor}}>
                                                    <div className={full_className}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={res.title}
                                                style={{ color:'black' }}
                                            />
                                        </ListItem>
                                    </List>
                                    <Divider variant="middle" />
                                </OpenItem>
                            );
                        }
                    } else return "";
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    styles: state.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Search);
