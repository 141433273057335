import React from "react";
import Parser from "html-react-parser";
import {handleConnector} from "./Category.functions";
import {bindActionCreators} from "redux";
import {requestGetConnectorsData} from "../../actions";
import {connect} from "react-redux";
import {ShapperLoader} from "../../components/ShapperLoader/ShapperLoader";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Category from "./Category"
import CategoryListMap from "./ListMap/ListMap";
import CategoryProduct from "./CategoryProduct/CategoryProduct";
import AnnounceCategory from "../Announce/Announce.List";
import {CategoryList} from "./List/Category.List";

class CategoryMain extends React.Component {
    constructor(props) {
        super(props);

        if(this.props.dataPage.connector_id){
            if(this.props.connectors.success){
                this.state = {
                    loading: true,
                    loading_update: false
                };
            } else {
                this.state = {
                    loading: false,
                    loading_update: false
                };
            }
        } else {
            this.state = {
                loading: false,
                loading_update: false
            };
        }
    }

    async componentDidMount() {
        window.scrollTo(0,0);

        if(this.props.dataPage.connector_id){
            if(this.props.connectors.success){
                handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                    this.setState({loading: isLoading});
                })
            }
        }
    }

    async componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.props.dataPage.connector_id){
            if(this.props.connectors.success){

                if(Object.keys(prevProps.variableStorage).length === 0){
                    if(Object.keys(this.props.variableStorage).length > 0){
                        this.setState({loading_update: true});
                        handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                            this.setState({loading_update: isLoading});
                        })
                    }
                } else {
                    if(Object.keys(this.props.variableStorage).length > 0){
                        Object.entries(prevProps.variableStorage).forEach(([key, data]) => {
                            if(this.props.variableStorage[key]){
                                if (this.props.variableStorage[key] !== data){
                                    this.setState({loading_update: true});
                                    handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                                        this.setState({loading_update: isLoading});
                                    })
                                }
                            }
                        })
                    }
                }
            }
        }
    }

    render() {

        if(this.state.loading || this.state.loading_update){
            return <ShapperLoader inner/>
        }else {
            if (!this.props.dataPage.nodes) {
                return <h1>{Parser(this.props.dataPage.content)}</h1>;
            } else {

                if(this.props.dataPage.type === "list_map"){
                    return (
                        <CategoryListMap dataPage={this.props.dataPage}/>
                    )
                }

                if(this.props.dataPage.type === "category_product"){
                    return (
                        <CategoryProduct dataPage={this.props.dataPage} />
                    )
                }

                if(this.props.dataPage.type === "list_announce"){
                    return (
                        <AnnounceCategory dataPage={this.props.dataPage} />
                    )
                }

                if(this.props.dataPage.type === "category"){

                    if(this.props.dataPage.category_type === "list_title" || this.props.dataPage.category_type === "list_subtitle") {
                        return (
                            <CategoryList dataPage={this.props.dataPage} />
                        )
                    }

                    return (
                        <Category dataPage={this.props.dataPage}/>
                    )
                }

                return '';
            }
        }
    }
}

const mapStateToProps = (state) => ({
    connectors: state.connectors,
    variableStorage: state.variableStorage,
    styles: state.style,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetConnectorsData,
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps()))(CategoryMain);
